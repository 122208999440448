<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16"
  >
    <v-row class="mb-0 d-flex justify-center align-center">
      <v-col
        cols="12"
        sm="11"
        md="9"
        class="pb-0 mb-0"
      >
        <v-card class="white px-auto pb-0 mb-0">
          <v-stepper>
            <v-stepper-header>
              <v-stepper-step
                step="1"
                complete
                complete-icon="mdi-home-circle"
              >
                HOME INFO
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="2"
              >
                CHOOSE PLAN
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="3">
                PURCHASE PLAN
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="4">
                DOWNLOAD YOUR POLICY
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col
        cols="12"
        sm="11"
        md="9"
        class="mt-0 pt-1"
      >
        <v-card
          class="mt-0 mb-16"
        >
          <validation-observer
            ref="observer"
            v-slot="{ invalid }"
          >
            <v-form
              ref="form"
              class="px-8 font-weight-bold"
              @submit.prevent="submit"
            >
              <!-------------------------------------Personal Details---------------------------->
              <v-row>
                <v-col>
                  <div class="sub-title mt-2">
                    Personal Details
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Start Date"
                    rules="required"
                  >
                    <v-text-field
                      v-model="startDate"
                      label="Policy Start Date"
                      outlined
                      :error-messages="errors"
                      @click="clickedFieldGlobal('startDate', 30, 'Homeowners Survey v1')"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <div class="sub-heading">
                    Do you have an existing policy with Stillwater Insurance?
                  </div>
                  <v-radio-group
                    v-model="existingPolicy"
                    mandatory
                    row
                  >
                    <v-radio
                      label="No"
                      value="No"
                      @click="clickedFieldGlobal('existingPolicy: no', 30, 'Homeowners Survey v1')"
                    >
                    </v-radio>
                    <v-radio
                      label="Yes"
                      value="Yes"
                      @click="clickedFieldGlobal('existingPolicy: yes', 30, 'Homeowners Survey v1')"
                    >
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Year Built"
                    rules="required"
                  >
                    <v-text-field
                      v-model="yearBuilt"
                      v-mask="'####'"
                      label="Year Built"
                      outlined
                      readonly
                      placeholder="mm/dd/yyyy"
                      :error-messages="errors"
                      @click="clickedFieldGlobal('yearBuilt', 31, 'Homeowners Survey v1')"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Month Purchased"
                    rules="required"
                  >
                    <v-text-field
                      v-model="monthPurchased"
                      v-mask="'##'"
                      label="Month Purchased Property"
                      outlined
                      :error-messages="errors"
                      @click="clickedFieldGlobal('monthPurchased', 32, 'Homeowners Survey v1')"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Year Purchased"
                    rules="required"
                  >
                    <v-text-field
                      v-model="yearPurchased"
                      v-mask="'####'"
                      label="Year Purchased Property"
                      outlined
                      :error-messages="errors"
                      @click="clickedFieldGlobal('yearPurchased', 33, 'Homeowners Survey v1')"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="replacementCost"
                    label="Replacement Cost (if blank, we will calculate)"
                    outlined
                    disabled
                    @click="clickedFieldGlobal('replacementCost', 34, 'Homeowners Survey v1')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-------------------------------------No Follow Up Questions---------------------------->
              <v-row>
                <v-col>
                  <div class="sub-title mt-2">
                    We’ll need to ask a few questions in order to get you the best possible coverage options.
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="black--text font-weight-bold">
                    Select each item that applies to your home.
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <ul
                    style="list-style: none;"
                    class="mt-n6"
                  >
                    <li
                      v-for="(question, index) in noFollowups"
                      :key="index"
                    >
                      <v-checkbox
                        v-model="kickoutSelections[index]"
                        :label="question.question"
                        hide-details
                        true-value="Yes"
                        @change="handleError1(question.error, kickoutSelections[index])"
                        @click="clickedField(`${question.question}: ${kickoutSelections[index]}`, 35)"
                      ></v-checkbox>
                      <v-tooltip
                        v-if="question.tooltip"
                        top
                        max-width="250px"
                        color="grey darken-3"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            small
                            class="ml-2 mb-2"
                            color="primary"
                            style="font-size: 30px;"
                            v-bind="attrs"
                            outline
                            v-on="on"
                          >
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span>Tip</span>
                      </v-tooltip>
                    </li>
                    <li>
                      <v-checkbox
                        v-model="noneOfTheAbove1"
                        label=" None of the above"
                        hide-details
                        :error-messages="errors"
                        @change="clearKickoutQuestions"
                        @click="clickedFieldGlobal('noneOfTheAbove1', 36, 'Homeowners Survey v1')"
                      ></v-checkbox>
                    </li>
                  </ul>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-dialog
                  v-model="dialog1"
                  persistent
                  max-width="290"
                >
                  <v-card>
                    <v-card-title class="text-h5 red--text">
                      Exception
                    </v-card-title>
                    <v-card-text class="text-center">
                      {{ dialog1Error }}
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="dialog1 = false"
                      >
                        Ok
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
              <!-------------------------------------Questions with followups---------------------------->
              <v-row>
                <v-col>
                  <div class="black--text font-weight-bold">
                    Select each item that applies to your home.
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <ul
                    style="list-style: none;"
                    class="mt-n6"
                  >
                    <li
                      v-for="(question, index) in hasFollowups"
                      :key="index"
                    >
                      <div class="d-flex align-center">
                        <v-checkbox
                          v-model="followupSelections[question.qId]"
                          :label="question.question"
                          hide-details
                          true-value="Yes"
                          @change="clearNoneOfTheAbove"
                          @click="clickedField(`${question.question}: ${followupSelections[question.qId]}`, 37)"
                        ></v-checkbox>
                        <v-tooltip
                          v-if="question.tooltip"
                          top
                          max-width="250px"
                          color="grey darken-3"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              small
                              class="ml-2 mt-4"
                              color="primary"
                              style="font-size: 30px;"
                              v-bind="attrs"
                              outline
                              v-on="on"
                            >
                              mdi-information-outline
                            </v-icon>
                          </template>
                          <span>{{ question.tooltipMessage }}</span>
                        </v-tooltip>
                      </div>
                      <div v-if="followupSelections[question.qId] === true && question.subQuestionsCheck">
                        <span class="ml-6">Select each that applies</span>
                        <div
                          v-for="(subquestion, i) in question.subQuestionsCheck"
                          :key="i"
                          class="d-flex align-center"
                        >
                          <v-checkbox
                            v-model="followupQuestions[subquestion.qId]"
                            :label="subquestion.question"
                            hide-details
                            class="ml-6"
                            @change="handleError2(subquestion.error, followupQuestions[subquestion.qId], subquestion, followupQuestions, question)"
                            @click="clickedField(`${subquestion.question}: ${followupQuestions[subquestion.qId]}`, 38)"
                          >
                          </v-checkbox>
                          <v-tooltip
                            v-if="subquestion.tooltip"
                            top
                            max-width="250px"
                            color="grey darken-3"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                small
                                class="ml-2 mt-4"
                                color="primary"
                                style="font-size: 30px;"
                                v-bind="attrs"
                                outline
                                v-on="on"
                              >
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span>{{ subquestion.tooltipMessage }}</span>
                          </v-tooltip>
                        </div>
                      </div>
                      <div v-if="followupSelections[question.qId] === true && question.subQuestionsSelect">
                        <v-select
                          v-for="(q, n) in question.subQuestionsSelect"
                          :key="n"
                          v-model="followupQuestions[q.qId]"
                          :label="q.question"
                          outlined
                          hide-details
                          :items="q.answers"
                          class="ml-6 mt-4 mr-6"
                          @change="handleSelectError(q.error, followupQuestions[q.qId], q.kickoutCases)"
                        ></v-select>
                      </div>
                      <div v-if="followupSelections[question.qId] === true && question.subQuestionsText">
                        <span class="ml-6">Enter 0 if none</span>
                        <div
                          v-for="(sub, number) in question.subQuestionsText"
                          :key="number"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            name="Number of losses"
                            rules="required"
                          >
                            <v-text-field

                              v-model="followupQuestions[sub.qId]"
                              :label="sub.question"
                              outlined
                              :items="sub.answers"
                              class="ml-6 mt-4 mr-6"
                              :error-messages="errors"
                              @blur="handleTextError(sub.error, followupQuestions[sub.qId], sub.kickoutCase)"
                            ></v-text-field>
                          </validation-provider>
                        </div>
                      </div>
                    </li>
                    <li>
                      <v-checkbox
                        v-model="noneOfTheAbove2"
                        label=" None of the above"
                        hide-details
                        @change="clearFollowupQuestions"
                        @click="clickedFieldGlobal('noneOfTheAbove2', 39, 'Homeowners Survey v1')"
                      ></v-checkbox>
                    </li>
                  </ul>
                </v-col>
              </v-row>
              <!------------------------DISCOUNTS--------------------->
              <v-row>
                <v-col>
                  <div class="sub-title mt-2">
                    Discounts
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="sub-heading">
                    Does the residence have a smoke alarm, dead bolt and fire extinguisher?
                  </div>
                  <v-radio-group
                    v-model="smokeAlarm"
                    mandatory
                    row
                  >
                    <v-radio
                      label="Yes"
                      value="Yes"
                      @click="clickedFieldGlobal('smokeAlarm yes', 40, 'Homeowners Survey v1')"
                    >
                    </v-radio>
                    <v-radio
                      label="No"
                      value="No"
                      @click="clickedFieldGlobal('smokeAlarm no', 40, 'Homeowners Survey v1')"
                    >
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="sub-heading">
                    Does the residence have an active central station reporting fire alarm?
                  </div>
                  <v-radio-group
                    v-model="activeCentralReportingFireAlarm"
                    mandatory
                    row
                  >
                    <v-radio
                      label="Yes"
                      value="Yes"
                      @click="clickedFieldGlobal('activeCentralReportingFireAlarm yes', 41, 'Homeowners Survey v1')"
                    >
                    </v-radio>
                    <v-radio
                      label="No"
                      value="No"
                      @click="clickedFieldGlobal('activeCentralReportingFireAlarm no', 41, 'Homeowners Survey v1')"
                    >
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="sub-heading">
                    Does the residence have an active central station reporting burglar alarm?
                  </div>
                  <v-radio-group
                    v-model="activeCentralReportingBurglarAlarm"
                    mandatory
                    row
                  >
                    <v-radio
                      label="Yes"
                      value="Yes"
                      @click="clickedFieldGlobal('activeCentralReportingBurglarAlarm yes', 42, 'Homeowners Survey v1')"
                    >
                    </v-radio>
                    <v-radio
                      label="No"
                      value="No"
                      @click="clickedFieldGlobal('activeCentralReportingBurglarAlarm no', 42, 'Homeowners Survey v1')"
                    >
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Marital Status"
                    rules="required"
                  >
                    <v-select
                      v-model="maritalStatus"
                      :items="maritalStatuses"
                      :error-messages="errors"
                      outlined
                      label="What is the marital status of the Named Insured?"
                      @click="clickedFieldGlobal('maritalStatus', 43, 'Homeowners Survey v1')"
                    >
                    </v-select>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="sub-heading">
                    Is this property located in a gated community?
                  </div>
                  <v-radio-group
                    v-model="gatedCommunity"
                    mandatory
                    row
                  >
                    <v-radio
                      label="Yes"
                      value="Yes"
                      @click="clickedFieldGlobal('gatedCommunity yes', 44, 'Homeowners Survey v1')"
                    >
                    </v-radio>
                    <v-radio
                      label="No"
                      value="No"
                      @click="clickedFieldGlobal('gatedCommunity no', 44, 'Homeowners Survey v1')"
                    >
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="d-flex justify-end">
                    <v-btn
                      x-large
                      :disabled="invalid"
                      color="primary"
                      class="mb-2 font-weight-bold"
                      @click="next"
                    >
                      Next
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import moment from 'moment'
  const zipcodes = require('zipcodes')
  export default {
    data () {
      return {
        dialog1: false,
        dialog1Error: '',
        zipCode: '95035',
        propertyType: 'Single Family Home',
        propertyUse: 'Full-time primary residence - no other part-time residences',
        address: '840 Bolton Drive',
        city: 'Milpitas',
        state: 'CA',
        zip: '95035',
        dob: '05/23/1989',
        startDate: moment().format('MM/DD/YYYY'),
        lastName: 'Buttons',
        email: 'bob@buttons.com',
        yearBuilt: '1984',
        monthPurchased: '01',
        yearPurchased: '1985',
        replacementCost: '1138000',
        existingPolicy: '',
        noneOfTheAbove1: false,
        noneOfTheAbove2: false,
        smokeAlarm: '',
        activeCentralReportingFireAlarm: '',
        activeCentralReportingBurglarAlarm: '',
        maritalStatus: '',
        maritalStatuses: ['Married', 'Single', 'Divorced', 'Registered Domestic Partner', 'Widowed'],
        gatedCommunity: '',
        numberOfStories: [1, 1.5, 2, 2.5, 3, 3.5, 4],
        pool: ['Yes', 'No'],
        numbers: [1, 2],
        disable: false,
        kickoutSelections: {},
        followupSelections: {},
        followupQuestions: {},
        propertyTypes: ['Single Family Home', 'Townhome', 'Rowhome', 'Duplex'],
        propertyUses: ['Full-time primary residence - no other part-time residences', 'Primary residence with part-time residence(s) elsewhere', 'Secondary Residence with primary residence elsewhere', 'Property owned by the insured and rented to a tenant with a long term lease', 'Property owned by the insured and rented with a short term lease / vacation rental', 'Vacant Residence'],
        roofTypes: ['Roof Type 1', 'Roof Type 2', 'Roof Type 3', 'Roof Type 4'],
        constructionTypes: ['Type 1', 'Type 2', 'Type 3', 'Type 4'],
        numeric_only: [
          v => !!v || 'This field is required',
          v => /^[0-9]*$/.test(v) || 'Numeric values only',
        ],
      }
    },
    computed: {
      questions () {
        return this.$store.getters.getStillwaterCaliforniaQuestions.homeQuestions
      },
      noFollowups () {
        return this.questions.filter(obj => obj.followUps === 'No')
      },
      hasFollowups () {
        return this.questions.filter(obj => obj.followUps === 'Yes')
      },
      allzips () {
        let usazips = zipcodes.radius(67052, 1500)
        console.log('codes loaded!  ', usazips[0])
        let codes = usazips.slice(0, 4)
        console.log(codes)
        return usazips
      },
      basicSelected () {
        return this.selectedFixtures === 'Basic'
      },
      extraSelected () {
        return this.selectedFixtures === 'Extra'
      },
      topSelected () {
        return this.selectedFixtures === 'Top'
      }
    },
    methods: {
      clearKickoutQuestions () {
        if (this.noneOfTheAbove1 === true) {
          this.kickoutSelections = {}
        }
      },
      clearFollowupQuestions () {
        if (this.noneOfTheAbove2 === true) {
          this.followupSelections = {}
          this.followupQuestions = {}
        }
      },
      handleError1 (error, value) {
        if (value === true) {
          this.noneOfTheAbove1 = false
          this.dialog1 = true
          this.dialog1Error = error
          this.disable = true
        }
      },
      handleError2 (error, value, subquestion, followups, parent) {
        console.log(value, subquestion, followups)
        if (subquestion.clear) {
          console.log('clearing')
          let arr = []
          parent.subQuestionsCheck.forEach(el => { if (!el.clear) { arr.push(el.qId) } })
          for (let el in this.followupQuestions) {
            if (arr.includes(el)) {
              this.followupQuestions[el] = false
            }
          }
          return this.followupQuestions
        } else if (value === true) {
          let arr = []
          parent.subQuestionsCheck.forEach(el => { if (el.clear) { arr.push(el.qId) } })
          for (let el in this.followupQuestions) {
            if (arr.includes(el)) {
              this.followupQuestions[el] = false
            }
          }
          this.noneOfTheAbove2 = false
          this.dialog1 = true
          this.dialog1Error = error
          this.disable = true
          return this.followupQuestions
        }
      },
      clearNoneOfTheAbove (value) {
        if (value === true) {
          this.noneOfTheAbove2 = false
        }
      },
      handleSelectError (error, question, answers) {
        if (answers.includes(question)) {
          this.noneOfTheAbove2 = false
          this.dialog1 = true
          this.dialog1Error = error
          this.disable = true
        }
      },
      handleTextError (error, value, kickout) {
        if (value >= kickout) {
          this.noneOfTheAbove2 = false
          this.dialog1 = true
          this.dialog1Error = error
          this.disable = true
        }
      },
      getMailAddressData (addressData, placeResultData, id) {
        this.address = addressData.name
        this.city = addressData.locality
        this.zip = addressData.postal_code
        this.state = addressData.administrative_area_level_1
      },
      getpostcodedata (postcode) {
        console.log('looking zip code data ')
        let loc = zipcodes.lookup(postcode)
        console.log('zip code data ', loc)
        this.StateOrProvinceCode = loc.state
        this.$store.dispatch('checkpostcode', postcode)
        console.log(this.$store.getters.postcodedata)
      },
      setPlan (fixture) {
        this.selectedFixtures = fixture
      },
      async next () {
        if (this.disable) {
          window.alert('Can not continue, restart application')
          return
        }
        let complete = await this.$refs.observer.validate()
        console.log(complete)
        if (!complete) {
          console.log('Error validating form')
        } else {
          // this.$store.dispatch('setTravelDetails', this.travelDetails)
          this.$router.push({ path: '/homeowners/coverages' })
        }
      // post form data to api
      }
    }
  }
</script>

<style lang="scss" scoped>
.green-background {
  background-color: #D8FDD7;
}
.title-text {
  font-weight: normal;
  font-size: 36px;
  color: #00A1B7;
}
.sub-title{
  font-size: 20px;
  font-weight: 700;
  color: #00A1B7;
}
.plan-title {
  font-size: 30px;
}
.plan-sub {
  font-size: 26px;
}
.selected {
  background-color: #005F6C;
  color: white
}
</style>
